.freeBanner {
    background: linear-gradient(125deg, #5059C9 25.7%, #2D1278 120.57%);
    padding: 14px 20px 14px 30px;
    position: absolute;
    bottom: 0;
    width: calc(100% - 268px); // 268 menu, 28 padding
    z-index: 9999;
    height: 100px;

    .title {
        color: #FFF;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
    }

    .desc {
        color: #FFF;
        font-size: 12px;
        font-weight: 400;
        line-height: 160%;
    }
}

.banner {
    @extend .freeBanner;
    padding: 0 20px 0 30px;
    background: linear-gradient(125deg, #54BD95 25.7%, #202782 120.57%);

    .close {
        position: absolute;
        right: 30px;
        top: 38px;
        color: rgba(255, 255, 255, 0.45);
    }
}