.label {
  font-weight: 500;
}

.help {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
}

.pop {
  width: 326px;

  :global {
    .ant-popover-inner {
      padding: 24px;
    }
  }
}

.triggerBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}