.title {
  font-size: 18px;
  font-weight: 500;
  padding: 16px 16px 16px 30px;
  background: #FFF;
  margin: 0;
}

.upload {
  margin: 16px 30px 8px;
  height: auto;
  display: flex;
  flex-direction: column;

  :global {
    .ant-upload-drag {
      border: 1px solid #D9D9D9;
      border-radius: 16px;
      background: #FAFAFA;
      height: 154px;
    }
  }
}

.list {
  margin: 0 30px 16px;
  flex: 1;
  overflow: auto;

  li {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    div:first-child {
      flex: 1;
    }

    .btn {
      padding: 0;
      background: rgba(0, 0, 0, 0.05);
      margin-right: 16px;
      border: none;

      svg {
        font-size: 10px;
      }
    }
  }
}