.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.content {
  width: 760px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  display: flex;
  justify-self: center;
}

.stepsContainer {
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  padding: 24px 20px;
  margin-top: 10px;
  background-color: #fff;
}

.createBtn {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.numLabel {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.numValue {
  display: flex;
  justify-content: flex-start;
}

.selectFile {
  border-radius: 6px;
  width: 400px;
  margin-right: 6px;

  &.error {
    * {
      color: #F52230;
    }
  }
}

.insights {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 58px;

  li {
    margin-bottom: 6px;
  }
}

.feedback {
  position: absolute;
  right: 30px;
  bottom: 30px;
}