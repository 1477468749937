.pptType {
  border-radius: 6px;
  background: #D35230;
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: 16px;
  font-weight: 800;
  margin-right: 6px;
  flex-shrink: 0;
}

.csvType {
  @extend .pptType;
  background: #007A5A;
}

.deletedCsvType {
  @extend .pptType;
  background: rgba(0, 0, 0, 0.25);
}

.container {
  border-radius: 10px;
  width: 240px;
  padding: 12px 14px 12px 12px;
  border: 1px solid var(--light-usage-border-color-border, rgba(0, 0, 0, 0.10));
  background: #FFF;
  height: 58px;
  overflow: hidden;
}

.name {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.type {
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
}