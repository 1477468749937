.input {
    padding: 6px 6px 6px 42px;
    display: flex;
    text-indent: 180px;
    min-height: 44px !important;
    line-height: 32px !important;
    max-height: 60vh !important;
    background-color: #fff;
}

.sendBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 7px;
    bottom: 7px;
    border-radius: 4px;

    &:disabled {
        background-color: rgba(0, 0, 0, 0.25);
        border-color: transparent;
    }
}

.unActiveBar {
    width: 100%;
    height: 60px;
    padding: 14px 20px 14px 30px;
    background: linear-gradient(156deg, #BEBEBE 12.03%, #3E3E3E 165.8%);
    color: #FFF;

    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
}