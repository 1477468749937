.markdown {
    li {
        margin-left: 18px;
    }

    p,
    li,
    ol {
        line-height: 22px;
    }

    menu,
    ol,
    ul,
    li,
    a {
        list-style: auto;
    }
}