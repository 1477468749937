.pptModal {
  overflow: hidden;

  :global {
    .ant-modal {
      width: auto !important;
    }

    .ant-modal-body {
      height: 80vh;
      width: 80vw;
      position: relative;
    }

    .ant-modal-mask {
      height: 100vh;
    }
  }
}

.loading {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  top: 200px;
}