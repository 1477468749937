.main {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.tipText {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.noTipContainer {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
}

.noTip {
  width: fit-content;
}

.tipMain {
  cursor: pointer;

  .tipInfo {
    display: none;
    position: absolute;
    width: 80%;
    left: 50%;
    white-space: normal;
    transform: translateX(-50%);
    font-size: 12px;
    color: #ffffff;
    font-weight: normal;
    line-height: 20px;
    z-index: 1;
    word-wrap: break-word;
    border-radius: 2px;
    background-color: #252733;
    padding: 0 8px;
  }

  &:hover {
    .tipInfoShow {
      display: inline-block;
    }
  }
}
