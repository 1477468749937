.row {
    padding: 6px 32px;

    &.error {
        * {
            color: #FF4D4F
        }
    }

    &.processing {
        * {
            color: rgba(0, 0, 0, 0.25)
        }
    }
}