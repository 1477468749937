.response {
    display: flex;
    align-items: flex-start;
    padding: 16px 24px 16px 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.you {
    margin-right: 9px;
}

.system {
    background: #6A3AC7;
    color: #fff;
    margin-right: 9px;
}

.fixedArea {
    position: absolute;
    right: 30px;
    bottom: 0;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.toBottom {
    background-color: #fff;
    border-color: rgba(0, 0, 0, 0.10);
    color: #6A3AC7;
    margin-right: 6px;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
}