$scrollTrackColor: transparent;
$scrollBarColor: rgba(0, 0, 0, 0.25);
$scrollBarHoverColor: rgba(0, 0, 0, 0.25);

.shumiao-global-scroll-style,
.ant-drawer,
.ant-modal-root,
.ant-dropdown-menu,
.ant-select-dropdown,
.ant-dropdown,
.ant-popover,
.ant-layout {

  // 这样写是为了覆盖全局样式
  ::-webkit-scrollbar {
    /* 滚动条整体样式 */
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    /* 滚动条里面轨道 */
    background-color: $scrollTrackColor;
    border-radius: 0;
  }

  ::-webkit-scrollbar-thumb {
    /* 滚动条里面小方块 */
    border-radius: 4px;
    background-color: $scrollBarColor;

    &:hover {
      background-color: $scrollBarHoverColor;
    }
  }

  ::-webkit-resizer {
    display: none;
  }

  ::-webkit-scrollbar-corner {
    display: none;
  }

  * {
    /* 火狐的样式 */
    scrollbar-color: $scrollBarColor $scrollTrackColor;
    scrollbar-width: auto;
  }

  // 修改 antd 默认滚动条滑块颜色
  .rc-virtual-list-scrollbar-thumb {
    background-color: $scrollBarColor !important;

    &:hover {
      background-color: $scrollBarHoverColor !important;
    }
  }
}

// hover 上去再显示滚动条
.datarc-hover-show-scroll-bar {
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: $scrollBarColor;
  }
}