.slider {
  width: 268px !important;
  max-width: 268px !important;
  min-width: 268px !important;
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background: #1E1E1E !important;
  overflow: hidden;

  :global {
    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.progress {
  padding: 0;
  margin-bottom: 0 !important;
}