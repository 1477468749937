.flexRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}

.newChat {
    width: calc(100% - 32px);
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid #fff;
    border-radius: 100px;
    margin: 12px 16px 0;
}

.menuFooter {
    * {
        color: #fff;
    }

    .settingsBtn {
        background-color: transparent;
        border: none;
    }

    .trialWrapper {
        border-radius: 6px;
        background-color: #2f3136;
        padding: 10px;
        margin: 10px 16px 0;

        .upgradeBtn {
            background: #8865d4;
            padding: 2px 4px;
            height: auto;
            margin-left: 4px;
            border-radius: 10px;
            font-size: 10px;
        }

        .time {
            font-size: 9.5px;
            color: #b9bbbe;
        }
    }

    .accountInfoWrapper {
        @extend .flexRow;
        margin-top: 10px;
        padding: 10px 8px 10px 16px;
        background: #292b2f;
    }
}