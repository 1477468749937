.aQuestion {
    display: inline-flex;
    padding: 5px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.65);
    cursor: pointer;
    max-width: 100%;
}

.notification {
    border-radius: 4px;
    border-top: 4px solid #ff4d4f;

    :global {

        .ant-notification-notice-message,
        .ant-notification-notice-description {
            margin-left: 40px;
        }
    }
}

.warningNotification {
    @extend .notification;
    border-top-color: #FAAD14;

    .subTitle {
        color: rgba(0, 0, 0, 0.85);
    }

    .desc {
        color: rgba(0, 0, 0, 0.45);
        max-height: 80px;
        overflow: auto;
    }
}

.toPayConfirm {
    border-radius: 16px;

    :global {
        .anticon-exclamation-circle {
            display: none;
        }

        .ant-modal-content {
            background: linear-gradient(125deg, #5059C9 25.7%, #2D1278 120.57%);
            padding: 30px;

        }

        .ant-modal-confirm-title {
            color: #fff;
            font-size: 20px;
        }

        .ant-modal-confirm-content {
            color: rgba(255, 255, 255, 0.70);
            font-size: 12px;
        }

        .ant-modal-close-icon {
            color: rgba(255, 255, 255, 0.45);
        }
    }
}

.subscribeBtn {
    border-radius: 90px;
    font-size: 16px;
    color: #281475;
    font-weight: 500;
    line-height: 24px;
    border-radius: 90px;
    border: 0.66px solid #FCE865;
    background: #FBDA32;
    padding: 12px 20px;
    height: auto;
}

.subscribeBox {
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.10);
    padding: 12px 20px;
    margin-top: 20px;
}