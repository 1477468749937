.selected {
    color: #6A3AC7;
    border: 1px solid #6A3AC7;
    background: #F3EDF9;
}

.form {
    :global {
        .ant-form-item-label label {
            color: rgba(0, 0, 0, 0.45) !important;
        }
    }
}