.title {
  font-size: 18px;
  font-weight: 500;
  padding: 16px 16px 16px 30px;
  background: #FFF;
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.chatsWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
