.overFlowText {
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.menu {
    background: transparent;
    font-size: 16px;
    opacity: 0.7;

    * {
        color: #fff;
    }

    .row {
        padding: 6px 16px;
    }

    :global {
        .ant-list-item {
            color: rgba(255, 255, 255, 0.65);
            width: 100%;
            padding: 10px 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.10);
        }

        .ant-list-item .row {
            padding: 6px 16px;
            width: 100%;
            height: 36px;
        }

        .ant-list-item.selected .row {
            background: #34364A;
        }
    }

}

.layoutTitle {
    padding: 20px 8px 16px 16px;
    background: #401b77;
    font-weight: 500;
    font-size: 24px;
    color: #fff;
}

.chatRow {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    align-items: center;
    flex: 1;
    overflow: hidden;

    .delete {
        display: none;
    }

    &:hover {
        .delete {
            display: inline-block;
        }
    }
}

.menuList {
    :global {
        .ant-list-item {
            color: rgba(255, 255, 255, 0.65);
            width: 100%;
            padding: 7px 16px 7px 40px;
        }

        .ant-list-item:hover {
            cursor: pointer;
        }

        .ant-list-item.selected {
            background: #34364A;
        }


        .ant-list-item.unActive {
            color: rgba(255, 255, 255, 0.25);
        }

        .ant-list-item.selected.unActive {
            color: rgba(255, 255, 255, 0.25);
            opacity: 0.7;
        }
    }
}