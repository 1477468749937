.selectFile {
    width: 180px;
    margin-left: 12px;
    margin: 0;
    background: #fff;
    border-radius: 6px;

    &.disabled {
        background: transparent;
    }

    :global {
        .ant-select-selector {
            border-radius: 6px;
            border-color: rgba(0, 0, 0, 0.05);
            background: rgba(0, 0, 0, 0.05);
        }
    }
}     